const IconsMixin = {
    methods: {
        getIcon: (difference) => {
            if (difference >= -0.1 && difference <= 0.1) {
                return "el-icon-success color-success";
            } else if (difference >= -0.4) {
                return "el-icon-top color-danger";
            } else if (difference <= 0.4) {
                return "el-icon-bottom color-danger";
            } else if (difference >= -0.2) {
                return "el-icon-top-right color-warning";
            } else if (difference <= 0.2) {
                return "el-icon-bottom-right color-warning";
            }  else {
                return "el-icon-error";
            }
        },
        getActiveIcon: (active) => {
            if (active) {
                return "el-icon-success color-success";
            } else {
                return "el-icon-error color-danger";
            }
        },
    }
}

export default IconsMixin;
