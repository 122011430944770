<template>
  <div v-if="currentUser">
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <h2>Benvenuto {{ getFullName(currentUser) }}</h2>
        </div>
      </template>
      <!-- Dati -->
      <div v-if="hasApartments()">

        <el-descriptions :column="3" border class="margin-top table-fixed" direction="vertical" title="Info appartamento"
          @click="$router.push('/dashboard/appartamento/'+ currentUser.apartments[0].id)">
          <el-descriptions-item v-for="apartment in apartmentsFields" :key="apartment.field" :span="apartment.span">
            <template #label>
              <div class="justify-space-between">
                <span>
                  <i :class="apartment.icon"></i> {{ apartment.label }}
                </span>
              </div>
            </template>
            <span v-if="apartment.onClick" class="pointer" @click="apartment.onClick">{{ apartment.value }}</span>
            <span v-else>{{ apartment.value }}</span>
          </el-descriptions-item>
        </el-descriptions>
        <!-- End dati -->
        <!-- Letture -->
        <el-descriptions  :column="3" border class="margin-top table-fixed" direction="vertical" title="Info consumi ultimo anno">
          <el-descriptions-item :span="1">
            <template #label>
              <div class="justify-space-between">
                <span>
                  Consumi appartamento
                </span>
              </div>
            </template>
            <span> {{ currentUser.apartments[0].consumption }} </span>
          </el-descriptions-item>
  
          <el-descriptions-item :span="1">
            <template #label>
              <div class="justify-space-between">
                <span>
                  Consumi %
                </span>
              </div>
            </template>
            <span> {{ relativeConsumption.toFixed(2) + " " }} </span>
            <el-tooltip class="item" effect="dark"
                        :content="'Differenza consumi: ' + (difference).toFixed(2) + '%'">
              <i :class="getIcon(difference/100)"></i>
            </el-tooltip>
          </el-descriptions-item>
  
          <el-descriptions-item :span="1">
            <template #label>
              <div class="justify-space-between">
                <span>
                  Consumo ideale %
                </span>
              </div>
            </template>
            <span> {{ targetComsumption.toFixed(2) + " " }} </span>
          </el-descriptions-item>
  
        </el-descriptions>
        <!-- End letture -->
      </div>
      <div v-else>
        <p>Non hai nessun appartamento associato al momento</p>
        <p>Vai alla pagina di associazione appartamento per visualizzarne i consumi</p>
      </div>
    </el-card>
  </div>
</template>

<script>

import UserMixin from "@/mixins/UserMixin";
import AddressMixin from "@/mixins/AddressMixin";
import IconsMixin from "@/mixins/IconsMixin";

import {mapActions, mapGetters} from "vuex";

export default {
  name: "DashboardBasicUser",
  mixins: [UserMixin, AddressMixin, IconsMixin],
  props: ["user"],
  data() {
    return {
      apartmentsFields: [],
      relativeConsumption: 0,
      targetComsumption: 0,
      difference: 0
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    ...mapActions(["fetchUser"]),
    goTo(name) {
      this.$router.push({name})
    },
    hasApartments(){
      return this.currentUser.apartments.length>0
    }
  },
  async created() {
    await this.fetchUser(this.user);
    if (this.hasApartments()){
      this.relativeConsumption = this.currentUser.apartments[0].consumption / this.currentUser.apartments[0].condominium.totalConsumption * 100;
      this.targetComsumption = this.currentUser.apartments[0].kApartment / this.currentUser.apartments[0].condominium.totalK * 100;
      this.difference = this.relativeConsumption - this.targetComsumption;

      this.apartmentsFields = [
        {
          field: "address",
          value: this.stringifyAddress(this.currentUser.apartments[0].condominium.address),
          label: "Indirizzo",
          icon: "el-icon-location-outline",
          span: 2,
        }, {
          field: "owner",
          value: this.getFullName(this.currentUser.apartments[0].condominium.owner),
          label: "Owner",
          icon: "el-icon-user",
          span: 1,
          onClick: () => this.goToUser(this.currentUser.apartments[0].condominium.owner)
        }, {
          field: "description",
          value: this.currentUser.apartments[0].condominium.description,
          label: "Descrizione",
          icon: "el-icon-message",
          span: 3,
        }, {
          field: "floor",
          value: this.currentUser.apartments[0].floor,
          label: "Piano/scala",
          icon: "el-icon-user",
          span: 1,
        }, {
          field: "number",
          value: this.currentUser.apartments[0].number,
          label: "Numero appartamento",
          icon: "el-icon-user",
          span: 1,
        },
      ];
    }

    
  },
};

</script>

<style>

.card-header {
  justify-content: space-between;
  align-items: center;
}

.justify-space-between {
  justify-content: space-between;
}

.color-danger {
  color: #F56C6C
}

.color-success {
  color: #67C23A
}

.color-warning {
  color: #E6A23C
}
</style>
